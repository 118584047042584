import { sleep } from '../utils'

export const gallery = {
  index: 0,
  delay: 4000,
  medias: [
      'Alice035.jpg',
      'IMG-20210321-WA0011.jpg',
      'Photo-Periple-Rouen-2.JPG',
      'IMG-20210405-WA0000.jpg',
      'WhatsApp-Image-2021-04-13-at-08.07.54.jpeg',
      'WhatsApp-Image-2021-04-18-at-23.11.05.jpeg',
      'WhatsApp-Image-2021-05-01-at-22.06.51.jpeg',
      'WhatsApp-Image-2021-05-08-at-07.31.42.jpeg',
      'WhatsApp-Image-2021-05-12-at-00.39.37.jpeg',
      'WhatsApp-Image-2021-05-21-at-21.31.24.jpeg',
      'IMG-20210527-WA0000.jpg',
      'IMG-20210604-WA0006.jpg',
      'WhatsApp-Image-2021-06-11-at-23.54.02.jpeg',
      'WhatsApp-Image-2021-06-15-at-22.22.28.jpeg',
      'WhatsApp-Image-2021-06-25-at-21.52.04.jpeg',
      'IMG-20210703-WA0006.jpg',
      'IMG-20210704-WA0016.jpg',
      'IMG-20210716-WA0011.jpg',
      'IMG-20210722-WA0037.jpg',
      'IMG-20210726-WA0023.jpg',
      'IMG-20210806-WA0035.jpg',
      'IMG-20210810-WA0011.jpg',
      'IMG-20210818-WA0001.jpg'
  ].map(uri => {
    return {
      filename: 'content/gallery/' + uri,
      type: 'image'
    }
  }),
  jugglersNames: ['Valentina Santori', 'Johan Swartvagher', 'Thomas Dequidt', 'Paul Cretin', 'Pietro Selva Bonino', 'Sylvain', 'Valentina', 'Pietro', 'Thomas Dequidt', 'Valentina', 'Sylvain Pascal', 'Paul Cretin', 'Pietro Selva Bonino', 'Sylvain Pascal', 'Valentina Santori', 'Paul Cretin', 'Johan Swartvagher', 'Sylvain Pascal', 'Pietro Selva Bonino', 'Sylvain Pascal', 'Valentina Santori', 'Paul Cretin', 'Paul, Thomas, Johan, Pietro, Valentina et Sylvain'],
  guestsNames: ['Dominique Duthuit', 'Michal Kapral', 'Thomas Suel', 'Popay', 'Mélodie Brun', 'Marie-Do', 'Bénédicte', 'Tito', 'Benoit Boutry', 'Eleanor et Gaëlle', 'Jean Klein', 'Laurent Tixador', 'Fabrizio Solinas', 'Cédric Parizot', 'Sophie Borthwick', 'Marc Devillers', 'Lola Miesseroff', 'Marie Letellier', 'Andrea Sperotto', 'Coline Godinot', 'Mathilde Roy', 'Alexandre Verbiese', 'Quentin Blary'],
  guestsInfos: ['Chercheuse sonore', 'Marathonien', 'Poète', 'Street artist', 'Bergère', 'Autrice performeuse', 'Cartographe', 'Poète chanteur', 'Artist land art', 'Jongleuses balles', 'marin', 'Artiste plasticien', 'Expert zoomorphe', 'Anthropologue', 'Performeuse', 'Troubadour', 'Autrice', 'Chorégraphe - danseuse', 'Meilleur ami', 'Vidéaste', 'Danseuse et gribouilleuse', 'Bidouilleur sonore', 'Expert aéronautique'],

  init () {
      this.updateGallery()
  },

  fadeIn (item) {
    return new Promise(resolve => {
      const anim = item.animate([
        { opacity: 1 },
        { opacity: 0 },
      ], { duration: 500 })
      anim.onfinish = resolve
    })
  },

  fadeOut (item) {
    return new Promise(resolve => {
      const anim = item.animate([
        { opacity: 0 },
        { opacity: 1 },
      ], { duration: 500 })
      anim.onfinish = resolve
    })
  },

  display: {
    image (src, delay) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve([img, sleep(delay)])
        img.onerror = reject
        img.src = src
      })
    },

    video (src) {
      return new Promise((resolve, reject) => {
        const video = document.createElement('video')
        const promise = new Promise(resolve => {
          video.addEventListener('ended', () => resolve())
        })
        video.addEventListener('canplaythrough', () => {
          video.play().then(() => {
            resolve([video, promise])
          }).catch(reject)
        })
        video.addEventListener('error', reject)
        video.muted = true
        video.autoplay = true
        video.src = src
      })
    },
  },

  async updateGallery () {
      if (!this.medias[this.index]) {
        this.index = 0
      }

      const jugglerGif = document.querySelector('#juggling video')
      const jugglerName = document.querySelector('#juggling p')
      const guestGif = document.querySelector('#guest video')
      const guestName = document.querySelector('#guest p:first-of-type')
      const guestInfo = document.querySelector('#guest p:last-of-type')

      const i2 = this.index + 1
      jugglerGif.src = '/content/avatars/jugglers/' + (i2 < 10 ? '0' + i2 : '' + i2) + '.mp4';
      guestGif.src = '/content/avatars/guests/' + (i2 < 10 ? '0' + i2 : '' + i2) + '.mp4';
      jugglerName.textContent = this.jugglersNames[this.index]
      guestName.textContent = this.guestsNames[this.index]
      guestInfo.textContent = this.guestsInfos[this.index]

      const media = this.medias[this.index]
      const container = document.querySelector('#week-imgs-component')
      const currentItem = container.querySelector('figure :first-child')

      try {
        const [newItem, promise] = await this.display[media.type](media.filename, this.delay)
        currentItem.replaceWith(newItem)
        newItem.classList.add('clickable')
        await promise
      } catch (e) {
        await sleep(1000)
      } finally {
        this.index++
        this.updateGallery()
      }
  },

  async updateMedia () {
    if (!this.medias.length) return
    if (!this.medias[this.index]) {
      this.index = 0
    }
    const media = this.medias[this.index]
    const container = document.querySelector('#week-imgs-component')
    const currentItem = container.querySelector('figure :first-child')

    try {
      const [newItem, promise] = await this.display[media.type](media.filename)
      // await this.fadeIn(container)
      currentItem.replaceWith(newItem)
      newItem.classList.add('clickable')
      document.querySelector('#week-imgs-component figcaption').textContent = media.legend
      // await this.fadeOut(container)
      await promise
    } catch (e) {
      await sleep(1000)
    } finally {
      this.index++
      this.updateMedia()
    }
  },
}
