import { sounds } from './sounds'

function displayPopup (container) {
  const cross = container.querySelector('.cross')
  document.body.classList.add('with-popup', container.id)

  function closePopup ({ target }) {
    if (!target.classList.contains('cross') && target !== container) return
    document.body.classList.remove('with-popup', container.id)
    cross.removeEventListener('click', closePopup)
    container.removeEventListener('click', closePopup)
  }

  cross.addEventListener('click', closePopup)
  container.addEventListener('click', closePopup)
}

const popups = {
  onCreated () {
    // Do not activate popup if in live mode
    if (window.location.pathname === '/live') return

    // Intro popup
    if (localStorage.getItem('popup') === null) {
      localStorage.setItem('popup', true)
      displayPopup(document.getElementById('popup-project'))
      sounds.run()
      document.querySelector('#popup-project .cross').onclick = () => {
          document.querySelector('#arrow').classList.add('jump');
      }
    }

    // Images popup
    document.addEventListener('click', ({ target }) => {
      if (target.classList.contains('clickable')) {
        const container = document.getElementById('popup-img')
        const [item, other] = target.nodeName === 'IMG'
          ? [container.querySelector('img'), container.querySelector('video')]
          : [container.querySelector('video'), container.querySelector('img')]

        other.setAttribute('hidden', true)
        if (target.src.includes('massue1.svg')) {
          import(/* webpackMode: "eager" */`@/imgs/temp/massue3.svg`).then((img) => {
            item.src = img.default
          })
        } else {
          item.setAttribute('src', target.src)
          item.removeAttribute('hidden')
        }

        displayPopup(container)
      }
    })
  },
}

export { popups }
