import { Map, TileLayer } from 'leaflet'

import { getIconMarkers } from './commons'
import { updateText } from '../texts'

function computeDistance ([lat1, lon1], [lat2, lon2]) {
  const radPerDeg = Math.PI / 180
  const earthRadius = 6371 // in km
  const latDelta = (lat2 - lat1) * radPerDeg / 2
  const lonDelta = (lon2 - lon1) * radPerDeg / 2
  const a = Math.sin(latDelta) * Math.sin(latDelta) +
    Math.cos(lat1 * radPerDeg) *
    Math.cos(lat2 * radPerDeg) *
    Math.sin(lonDelta) * Math.sin(lonDelta)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return earthRadius * c // in km
}

export default {
  zoom: 13,
  delay: 5000,

  map: new Map('small-map', {
    zoomSnap: 0.25,
    attributionControl: false,
    zoomControl: false,
  }),
  tiles: new TileLayer('https://a.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: 'Map data &copy <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>.',
    maxZoom: 18,
    tileSize: 256,
    zoomOffset: 0,
  }),

  path: null,
  jugglerPos: null,
  userPos: null,
  jugglerIcon: null,
  pulseIcon: null,

  mutations: {
    coords ({ lat, lon }) {
      updateText(lat, 'lat')
      updateText(lon, 'lon')
    },
    acc: updateText,
    alt: updateText,
    last_point (value) {
      this.map.setView(value)
      this.jugglerPos = value
      if (this.userPos) {
        this.updateDistance()
      }
      this.jugglerIcon.setLatLng(value)
      this.pulseIcon.setLatLng(value)
    },
  },

  onCreated () {
    this.tiles.addTo(this.map)
    this.map.setView([49.49, 0.1], this.zoom)

    setInterval(this.animateInfos, this.delay)

    const icons = getIconMarkers()
    this.jugglerIcon = icons[0]
    this.pulseIcon = icons[1]
    this.jugglerIcon.addTo(this.map)
    this.pulseIcon.addTo(this.map)
    // init distance to juggler button click
    document.querySelector('#user-location-component input').onclick = this.onDistanceButtonClick.bind(this)
  },

  animateInfos () {
    const displayed = document.querySelector('#satellite .switched:not([hidden])')
    const next = displayed.nextElementSibling || document.querySelector('#satellite .switched')
    displayed.setAttribute('hidden', true)
    next.removeAttribute('hidden')
  },

  updateDistance () {
    const distance = computeDistance(this.jugglerPos, this.userPos)
    updateText(Math.round(distance), 'user_distance')
  },

  onDistanceButtonClick () {
    if (!('geolocation' in navigator)) return
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      this.userPos = [coords.latitude, coords.longitude]
      this.updateDistance()
    })
  },
}
