import { Icon, Marker } from 'leaflet'

import { renderTemplate } from '../../utils'
import cities from '@/data/cities.json'
import cityIconUrl from '@/imgs/city_icon.svg'
import ceremonyIconUrl from '@/imgs/ceremony_icon.svg'
import pulseIconUrl from '@/imgs/pulse_icon.svg'
import jugglerIconUrl from '@/imgs/temp/pictos.svg'

const cityIcon = new Icon({
  iconUrl: cityIconUrl,
  iconSize: [15, 15],
  iconAnchor: [7.5, 7.5],
})

const ceremonyIcon = new Icon({
  iconUrl: ceremonyIconUrl,
  iconSize: [15, 15],
  iconAnchor: [7.5, 7.5],
})

const jugglerIcon = new Icon({
  iconUrl: jugglerIconUrl,
  iconSize: [70, 70],
  iconAnchor: [70, 70],
})

const pulseIcon = new Icon({
  iconUrl: pulseIconUrl,
  iconSize: [30, 30],
  iconAnchor: [15, 15],
})

function getCityMarkers () {
  const template = document.getElementById('city-popup')
  return Object.entries(cities).map(([name, coords]) => {
    const marker = new Marker(coords, { icon: cityIcon })
    marker.bindPopup(() => renderTemplate(template, { name }), { closeButton: false })
    return marker
  })
}

function getIconMarkers (coords) {
  return [
    new Marker([0, 0], { icon: jugglerIcon }),
    new Marker([0, 0], { icon: pulseIcon }),
  ]
}

export {
  cityIcon,
  ceremonyIcon,
  getCityMarkers,
  getIconMarkers,
}
