export const plot = {
  delay: 8000,
  drops: [],
  method: 'curve',

  mutations: {
    wandering_drops (value) {
      this.drops[this.drops.length - 1] = value
      this.render()
    },
  },

  init (drops) {
    this.drops = drops
    this.render()
  },

  onCreated () {
    window.addEventListener('resize', this.render.bind(this))

    setInterval(() => {
      this.method = this.method === 'curve' ? 'histo' : 'curve'
      this.render()
    }, this.delay)
  },

  render () {
    const container = document.getElementById('graph-component')
    const padding = 50
    const cw = container.offsetWidth
    const ch = container.offsetHeight
    const w = cw - padding * 2
    const h = ch - padding * 2

    // merge all wanderings drops as a flattened array
    const drops = this.drops.reduce((merged, arr) => merged.concat(arr), [])
    // compute base height scale
    const [upperValue, steps] = this.findScale(this.getScale[this.method](drops))
    // compute ratios
    const xScaleRatio = w / drops.length
    const yScaleRatio = h / upperValue

    // create horizontal bars and legends
    const yBarsCount = upperValue / steps
    const yBarsHeight = yScaleRatio * steps
    const yBars = []
    const yLegends = []
    for (let i = 0; i <= yBarsCount; i++) {
      const y = i * yBarsHeight
      yBars.push(`<path class="line-dot" d="M0,${y} h${w}" />`)
      yLegends.push(`<text x="0" y="${y}" >${steps * (yBarsCount - i)}</text>`)
    }

    // create vertical bars and legends
    const wanderingsCount = this.drops.length
    const xBars = []
    const xLegends = []
    let daysCount = 0
    for (let i = 0; i < wanderingsCount; i++) {
      const wDays = this.drops[i].length
      daysCount += wDays
      const x = daysCount * xScaleRatio
      xBars.push(`<path d="M${x},0 v${h}" />`)
      xLegends.push(`<text x="${x - (wDays / 2 * xScaleRatio)}" y="0" >${i + 1}</text>`)
    }

    // draw line or rects
    const drawing = this.getDrawing[this.method](drops, xScaleRatio, yScaleRatio)

    container.innerHTML = `
      <svg width="${cw}" height="${ch}" viewBox="0 0 ${cw} ${ch}">
        <g transform="translate(${padding}, ${ch - padding}) scale(1, -1)">
          ${drawing}
          <g class="line-dot">
            ${yBars.join('')}
            ${xBars.join('')}
          </g>
        </g>
        <g class="texty" transform="translate(${padding - 10}, ${padding})">
          ${yLegends.join('')}
        </g>
        <g class="textx" transform="translate(${padding}, ${ch - padding + 15})">
          ${xLegends.join('')}
        </g>
        <path class="line" d="M${padding},${padding} ${padding},${ch - padding}"/>
        <path class="line" d="M${padding},${ch - padding} ${cw - padding},${ch - padding}"/>
        <text class="legendx" x="${ch / 2}" y="${-cw + padding - 15}" class="info">Nombre de massues tombées</text>
        <text x="${cw / 2}" y="${padding - 15}" class="info">Errances</text>
      </svg>
    `
  },

  getScale: {
    histo (drops) {
      // return highest value
      return Math.max(...drops)
    },

    curve (drops) {
      // return sum of all drops
      return drops.reduce((sum, value) => sum + value, 0)
    },
  },

  getDrawing: {
    histo (drops, xScaleRatio, yScaleRatio) {
      const rects = []
      for (let i = 0, count = drops.length; i < count; i++) {
        rects.push(`<rect x="${i * xScaleRatio}" y="0" width="${xScaleRatio}" height="${drops[i] * yScaleRatio}"/>`)
      }
      return rects.join('')
    },

    curve (drops, xScaleRatio, yScaleRatio) {
      const points = []
      let sum = 0
      for (let i = 0, count = drops.length; i < count; i++) {
        sum += drops[i]
        points.push(`${i * xScaleRatio},${sum * yScaleRatio}`)
      }
      return `<path class="curve" transform="translate(${xScaleRatio / 2})" d="M${points.join(' ')}" />`
    },
  },

  findScale (max) {
    const pow = Math.pow(10, max.toString().length - 1)
    const pow2 = pow / 10
    const reduced = max / pow

    let steps = 0
    if (reduced <= 2) {
      steps = 2
    } else if (reduced <= 5) {
      steps = 5
    } else {
      steps = 10
    }

    const upperNumber = reduced * 10
    const rest = upperNumber % steps
    return [
      (rest ? upperNumber + steps - rest : upperNumber) * pow2,
      steps * pow2,
    ]
  },
}
