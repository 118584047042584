import api from './api'
import store from './store'
import * as components from './components'

function main (data, reset) {
  const { state, wanderings, ceremonies } = data
  const wandering = wanderings.find(w => w.id === state.wandering_id)

  // console.log('STATE DATA', state)
  // console.log('CURRENT WANDERING DATA', wandering)
  // console.log('CEREMONIES', ceremonies)
  // console.log('WANDERINGS', wanderings)

  if (reset) {
    components.map.reset()
    components.posts.reset()
  }

  components.map.addCeremonies(ceremonies)
  for (let i = 0; i < ceremonies.length; i++) {
    if (wanderings[i]) {
      components.map.addWandering(wanderings[i], wanderings[i + 1] === undefined, ceremonies)
    } else if (ceremonies[i + 1]) {
      components.map.addTempWandering(ceremonies[i], ceremonies[i + 1])
    }
  }
  if (wanderings.length > 0) {
    components.posts.init(ceremonies, wanderings, wandering)
  }
  components.plot.init(wanderings.map(w => w.drops))
  components.gallery.init()

  store.setData(state)
  if (wandering) {
    store.setData(wandering)
  }
}

function onLoad () {
  for (const [componentName, component] of Object.entries(components)) {
    if (component.mutations) {
      store.register(componentName, Object.keys(component.mutations))
    }
    if (component.onCreated) component.onCreated()
  }

  // Live mode
  // if (window.location.pathname === '/live') {
  //   initLive()
  // }

  api.get('/init_data.json').then(data => {
    main(data)
  })
}

window.addEventListener('load', onLoad)
