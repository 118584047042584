import { Map, Polyline, Marker, LatLngBounds, LatLng, LayerGroup, svg } from 'leaflet'

import { getCityMarkers, ceremonyIcon, getIconMarkers } from './commons'
import { renderTemplate, toHumanDate } from '../../utils'

const color = {
  w: 'black',
  wHover: 'red',
  tempW: 'grey',
}

export default {
  maxBounds: new LatLngBounds(new LatLng(53.657, -10.481), new LatLng(38.048, 14.831)),
  fitBounds: new LatLngBounds(new LatLng(51.808, -6.152), new LatLng(41.393, 9.799)),
  map: new Map('map', { zoomSnap: 0.25, renderer: svg({ tolerance: 40 }) }),
  currentPath: null,
  currentPathLeft: null,
  jugglerIcon: null,
  pulseIcon: null,
  cityLayer: null,
  ceremonyLayer: null,
  pathLayer: null,

  onCreated () {
    this.map.zoomControl.setPosition('bottomright')
    this.map.setMaxBounds(this.maxBounds)
    this.map.fitBounds(this.fitBounds)
    this.cityLayer = new LayerGroup()
    this.cityLayer.addTo(this.map)
    getCityMarkers().forEach(marker => marker.addTo(this.cityLayer))
    const icons = getIconMarkers()
    this.jugglerIcon = icons[0]
    this.pulseIcon = icons[1]
    this.jugglerIcon.addTo(this.map)
    this.pulseIcon.addTo(this.map)
    this.init()
  },

  init () {
    this.ceremonyLayer = new LayerGroup()
    this.pathLayer = new LayerGroup()
    this.ceremonyLayer.addTo(this.map)
    this.pathLayer.addTo(this.map)
  },

  reset () {
    this.currentPath = null
    this.currentPathLeft = null
    this.map.removeLayer(this.ceremonyLayer)
    this.map.removeLayer(this.pathLayer)
    this.init()
  },

  updatePath (coord) {
    if (this.currentPath !== null) {
      this.currentPath.addLatLng(coord)
    }
    if (this.currentPathLeft !== null) {
      const points = this.currentPathLeft.getLatLngs()
      const { lat, lng } = points[points.length - 1]
      this.currentPathLeft.setLatLngs([coord, [lat, lng]])
    }
    this.jugglerIcon.setLatLng(coord)
    this.pulseIcon.setLatLng(coord)
  },

  addWandering (wandering, last, ceremonies) {
    const template = document.getElementById('wandering-popup')
    const path = new Polyline(wandering.path, { color: 'black' })
    path.on('mouseover', ({ sourceTarget }) => {
      sourceTarget.setStyle({ color: color.wHover })
    })
    path.on('mouseout', ({ sourceTarget }) => {
      sourceTarget.setStyle({ color: color.w })
    })
    path.addTo(this.pathLayer)
    path.bindPopup(() => {
      const cardItem = renderTemplate(template, wandering)
      const linkToPosts = document.createElement('button')
      linkToPosts.classList.add('btn')
      linkToPosts.textContent = 'Voir les posts'
      cardItem.lastElementChild.appendChild(linkToPosts)
      linkToPosts.onclick = () => {
        const wanderingLink = document.querySelector(`#wanderings [data-id="${wandering.id}"] a`)
        if (wanderingLink) {
          wanderingLink.click()
          wanderingLink.scrollIntoView()
        }
      }
      return cardItem
    }, { closeButton: false })

    if (last) {
      this.currentPath = path
      const ceremony = ceremonies.find(ceremony => wandering.ending_ceremony_id === ceremony.id)
      const lastPoint = wandering.path[wandering.path.length - 1]
      if (ceremony.lat !== lastPoint[0] && ceremony.lon !== lastPoint[1]) {
        this.currentPathLeft = new Polyline([lastPoint, [ceremony.lat, ceremony.lon]], {
          color: color.tempW, dashArray: '2, 5',
        })
        this.currentPathLeft.addTo(this.pathLayer)
      }
    }
  },

  addTempWandering (c1, c2) {
    const line = new Polyline([[c1.lat, c1.lon], [c2.lat, c2.lon]], {
      color: color.tempW, dashArray: '2, 5',
    })
    line.addTo(this.pathLayer)
  },

  addCeremonies (ceremonies) {
    const template = document.getElementById('ceremony-popup')
    ceremonies.forEach((ceremony, index) => {
      const c = { ...ceremony }
      const marker = new Marker([ceremony.lat, ceremony.lon], { icon: ceremonyIcon, zIndexOffset: 1000 })
      c.index = index + 1
      c.date = toHumanDate(ceremony.date)
      marker.bindPopup(() => {
        const cardItem = renderTemplate(template, c)
        if (c.start_of_id !== null) {
          const linkToHandoverPost = document.createElement('button')
          linkToHandoverPost.classList.add('btn')
          linkToHandoverPost.textContent = 'Voir le post de passation'
          cardItem.lastElementChild.appendChild(linkToHandoverPost)
          linkToHandoverPost.onclick = () => {
            const wanderingLink = document.querySelector(`#wanderings [data-id="${c.start_of_id}"] a`)
            if (wanderingLink) {
              wanderingLink.click()
              const handoverLink = document.querySelector('.handover-post a')
              if (handoverLink) handoverLink.click()
            }
          }
        }
        return cardItem
      }, { closeButton: false })
      marker.addTo(this.ceremonyLayer)
    })
  },

  mutations: {
    dir (value) {
      const pin = document.getElementById('compass-pin')
      pin.style.transform = `rotate(${value}deg)`
    },

    last_point (value) {
      this.updatePath(value)
    },
  },
}
