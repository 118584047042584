function updateBanners (value, prop) {
  const container = document.querySelector(`[data-prop=${prop}]`)
  if (value) {
    container.querySelector('p').textContent = value
    container.removeAttribute('hidden')
  } else {
    container.setAttribute('hidden', true)
  }
}

export function updateText (value, prop) {
  document.querySelector(`[data-prop=${prop}]`).textContent = value
}

function updateSrc ({ filepath, type }, prop) {
  const container = document.querySelector(`[data-src=${prop}]`)
  const [other, item] = type === 'video'
    ? [container.firstElementChild, container.lastElementChild]
    : [container.lastElementChild, container.firstElementChild]

  item.setAttribute('src', '/' + filepath)
  other.setAttribute('hidden', true)
  item.removeAttribute('hidden')
}

export const texts = {
  mutations: {
    // Simple texts
    // title: updateText,
    // juglability: updateText,
    // juggler_name: updateText,
    // duo_name: updateText,
    // duo_note: updateText,
    // spd: updateText,
    // transport: updateText,
    // info: updateText,
    // next_ceremony_city: updateText,
    // next_ceremony_adress: updateText,
    // next_ceremony_date: updateText,
    // wandering_id: updateText,
    // Banners
    // duo_extra: updateBanners,
    // texta: updateBanners,
    // textb: updateBanners,
    // textc: updateBanners,
    // textd: updateBanners,
    // src
    // juggler_avatar: updateSrc,
    // duo_avatar: updateSrc,

    'day_number' (value, prop) {
      let [day, total] = value
      let progressbar = day / total * 100
      if (day <= 0) {
        day--
        progressbar = 0
      }
      updateText(day, prop)
      updateText(total, 'days')
      const bar = document.querySelector('#travel span')
      bar.style.width = progressbar + '%'
    },

    // 'meteo' (value) {
    //   import(/* webpackMode: "eager" */`@/imgs/meteo/${value}.svg`).then((img) => {
    //     document.querySelector('[data-prop=meteo]').src = img.default
    //   })
    // },
  },
}
