const humanDateFormatter = new Intl.DateTimeFormat('fr-FR', {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  timeZone: 'GMT'
})

export function renderTemplate (template, data) {
  const clone = document.importNode(template.content, true)
  const elements = clone.querySelectorAll('[data-t]')
  for (const elem of elements) {
    if (elem.dataset.html) {
      elem.innerHTML = data[elem.dataset.t]
    } else {
      elem.textContent = data[elem.dataset.t]
    }
  }
  return clone
}

export function sleep (ms = 1000) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function getRandomInt (min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

export function toHumanDate (date) {
  return humanDateFormatter.format(new Date(date))
}
