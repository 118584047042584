import { renderTemplate, toHumanDate } from '../utils'
import api from '../api'

export const posts = {
  wanderings: [],

  _findWandering (id) {
    return this.wanderings.find(w => w.id === id)
  },

  init (ceremonies, wanderings, wandering) {
    this.wanderings = wanderings
    // const now = Date.now()
    // const nextCeremony = ceremonies.find(c => new Date(c.date) > now)
    this.fillHandoverShroudImgs()
    // if (nextCeremony) {
    //   this.fillNextCeremony(nextCeremony)
    // }
    // this.fillCeremonies(ceremonies)
    this.fillWanderings(wanderings)

    let hash = window.location.hash
    let w = wanderings[0].id
    if (hash) {
      // Meh
      let [wHash, pHash] = hash.replace('#', '').split('-').map(n => parseInt(n))
      const wObj = this._findWandering(wHash)
      pHash -= 1
      if (wObj && (pHash > 0 ? wObj.posts[pHash] : wObj.handover_post)) {
        w = wHash
      } else {
        window.location.hash = hash = ''
      }
    }

    this.addWanderingPostList(w).then(body => {
      if (hash) {
        body.querySelector(`[href="${hash}"]`).scrollIntoView()
      }
    })
    document.querySelector(`#wanderings [data-id="${w}"]`).classList.add('opened')
  },

  reset () {
    document.querySelector('#program').innerHTML = ''
    document.querySelector('#wanderings ul').innerHTML = ''
    document.getElementById('shroud-component').innerHTML = ''
    document.querySelector('#post-body .day-container').innerHTML = ''
    document.querySelector('#post-head').innerHTML = ''
  },

  fillNextCeremony (ceremony) {
    const elems = document.querySelectorAll('#ceremony [data-content]')
    for (const elem of elems) {
      if (elem.dataset.content === 'date') {
        elem.textContent = toHumanDate(ceremony[elem.dataset.content])
      } else {
        elem.textContent = ceremony[elem.dataset.content]
      }
    }
  },

  fillCeremonies (ceremonies) {
    const template = document.getElementById('ceremony-list-item')
    const docFragment = document.createDocumentFragment()
    ceremonies.forEach((ceremony, index) => {
      const c = { ...ceremony }
      c.index = index + 1
      c.date = new Intl.DateTimeFormat('fr-FR').format(new Date(c.date))
      docFragment.appendChild(renderTemplate(template, c))
    })
    document.querySelector('#program').appendChild(docFragment)
  },

  fillWanderings (wanderings) {
    const wListTmp = document.getElementById('wandering-list-item')
    const wListDocFragment = document.createDocumentFragment()
    for (const w of wanderings) {
      const wListItem = renderTemplate(wListTmp, { index: w.id })
      wListItem.querySelector('li').dataset.id = w.id
      wListItem.querySelector('a').onclick = this.onWanderingItemClick.bind(this)
      wListDocFragment.appendChild(wListItem)
    }
    const wListCont = document.querySelector('#wanderings ul')
    wListCont.appendChild(wListDocFragment)
  },

  fillHandoverShroudImgs () {
    const shroudImgsDocFragment = document.createDocumentFragment()
    const shroudImgItemTmp = document.getElementById('shroud-img-item')

    for (const w of this.wanderings) {
      const post = w.handover_post
      if (post === null) continue
      const item = renderTemplate(shroudImgItemTmp, { legend: post.shroud_note })
      item.querySelector('img').src = post.shroud_img
      shroudImgsDocFragment.appendChild(item)
    }
    document.getElementById('shroud-component').appendChild(shroudImgsDocFragment)
  },

  buildPost (tmp, post, href, isHandover = false) {
    const wPostItem = document.importNode(tmp.content, true)
    if (isHandover) {
      wPostItem.querySelector('a').textContent = 'Cérémonie de passation'
    } else {
      wPostItem.querySelector('time').textContent = toHumanDate(post.date).split(' à ')[0]
    }
    const wPostElem = wPostItem.querySelector('a')
    wPostElem.href = href
    wPostElem.dataset.uri = post.uri
    wPostElem.onclick = this.onPostItemClick.bind(this)
    return wPostItem
  },

  async addWanderingPostList (id) {
    const wPostItemTmp = document.getElementById('wandering-post-item')

    const w = this._findWandering(id)
    const wHeadTmp = document.getElementById('wandering-head')
    const wHeadItem = renderTemplate(wHeadTmp, w)

    const wPostListDocFragment = document.createDocumentFragment()
    if (w.handover_post) {
      const href = `#${id}-0`
      const handoverPost = this.buildPost(wPostItemTmp, w.handover_post, href, true)
      handoverPost.querySelector('li').classList.add('handover-post')
      wPostListDocFragment.appendChild(handoverPost)
    }
    const now = Date.now()
    for (const post of w.posts) {
      // if post in future, do not display
      if (new Date(post.date) > now) continue
      const href = `#${id}-${w.posts.indexOf(post) + 1}`
      wPostListDocFragment.appendChild(this.buildPost(wPostItemTmp, post, href))
    }

    const wBodyContainer = document.querySelector('#post-body .day-container')
    wBodyContainer.innerHTML = ''
    wBodyContainer.appendChild(wPostListDocFragment)

    const data = await Promise.all(Array.from(wBodyContainer.children).map(post => {
      return api.get(post.firstElementChild.dataset.uri)
    }))
    Array.from(wBodyContainer.children).forEach((post, i) => {
      this.addPost(post, data[i])
      post.classList.add('opened')
    })

    const wHeadContainer = document.querySelector('#post-head')
    wHeadContainer.innerHTML = ''
    wHeadContainer.appendChild(wHeadItem)

    return wBodyContainer
  },

  addPost (parent, data) {
    // function buildMedia (type, filename, legend) {
    function buildMedia (type, filename) {
      const fig = document.createElement('FIGURE')
      // const cap = document.createElement('FIGCAPTION')
      const med = document.createElement(type === 'image' ? 'IMG' : type)
      if (type !== 'image') {
        med.setAttribute('controls', true)
      } else {
        med.classList.add('clickable')
      }
      med.setAttribute('src', '/' + filename)
      // cap.textContent = legend
      fig.appendChild(med)
      // fig.appendChild(cap)
      return fig
    }

    const postTmp = document.getElementById('wandering-post')
    const postItem = renderTemplate(postTmp, data)

    const galleryCont = postItem.querySelector('.gallery')
    const textCont = postItem.querySelector('.text')
    const hasMedia = data.shroud_img !== undefined || data.medias.length > 0 || data.video_link

    if (hasMedia) {
      const tempGalleryTmp = document.createDocumentFragment()
      if (data.video_link) {
        const iframe = document.importNode(document.getElementById('post-video').content, true)
        iframe.querySelector('iframe').src = `https://player.vimeo.com/video/${data.video_link}?byline=0&portrait=0`
        // console.log(iframe)
        tempGalleryTmp.appendChild(iframe)
      }
      if (data.shroud_img) {
        tempGalleryTmp.appendChild(buildMedia('image', data.shroud_img, data.shroud_note))
      }
      // for (const { type, filename, legend } of data.medias) {
      //   tempGalleryTmp.appendChild(buildMedia(type, filename, legend))
      // }
      for (const { type, filename } of data.medias) {
        tempGalleryTmp.appendChild(buildMedia(type, filename))
      }
      galleryCont.appendChild(tempGalleryTmp)
    } else {
      galleryCont.parentElement.removeChild(galleryCont)
      textCont.classList.add('full-width')
    }

    if (!data.content) {
      textCont.parentElement.removeChild(textCont)
      galleryCont.classList.add('full-width')
    }

    parent.appendChild(postItem)

    return parent.lastElementChild
  },

  onWanderingItemClick ({ currentTarget }) {
    const parent = currentTarget.parentElement
    if (parent.classList.contains('opened')) return

    const prev = parent.parentElement.querySelector('.opened')
    if (prev) prev.classList.remove('opened')
    parent.classList.add('opened')
    this.addWanderingPostList(parseInt(parent.dataset.id))
  },

  async onPostItemClick ({ currentTarget }) {
    const parent = currentTarget.parentElement
    if (parent.classList.contains('opened')) {
      parent.classList.remove('opened')
      return
    }
    let post = parent.querySelector('.content-container')
    if (post === null) {
      const data = await api.get(currentTarget.dataset.uri)
      post = this.addPost(parent, data)
    }
    // const prev = parent.parentElement.querySelector('.opened')
    // if (prev) {
    //   prev.classList.remove('opened')
    // }
    parent.classList.add('opened')
    parent.scrollIntoView()
  },
}
