import { sleep, getRandomInt } from '../utils'

export const sounds = {
  file: 'content/sounds/voice.mp3',
  retryDelay: 1000,
  delay: 500,
  fileIndex: 0,

  mutations: {
    voices_files (voiceFiles) {
      // this.files = voiceFiles
    },
  },

  pickFile () {
    for (const [i, file] of Object.entries(this.files)) {
      if (i > this.index) {
        this.index = i
        return file
      }
    }
    if (this.files.length > 0) {
      this.index = 0
      return this.files[0]
    }
    return null
  },

  pickRandomFile () {
    const files = this.files.filter(file => file !== '')
    if (files.length) {
      return files[getRandomInt(0, files.length)]
    }
    return null
  },

  async run (delay = this.delay) {
    await sleep(delay)
    const file = this.file

    // if (!file) return this.run(this.retryDelay)

    const audio = new Audio(file)
    audio.addEventListener('canplaythrough', e => {
      audio.play().then(a => {
        // audio.addEventListener('ended', () => {
        //   this.run()
        // })
      }).catch(() => {
        // user may not had interacts so the audio can't play, retry later
        this.run()
      })
    })
  },

  onCreated () {
    // this.run(this.retryDelay)
  },
}
