export const credits = {
    onCreated () {
        document.getElementById('credits-button').onclick = (e) => {
            const moreInfos = document.getElementById('show-more');
            moreInfos.classList.remove('hide')
            e.currentTarget.classList.add('hide')
            moreInfos.scrollIntoView();
        }
    }
}
