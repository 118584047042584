function updateCounter (value, prop) {
  document.querySelector(`[data-prop="${prop}"`).innerHTML = value.toString().split('').map(number => {
    return `<span>${number}</span>`
  }).join('')
}

export const kms = {
  interval: 5000,
  kmsTotal: 0,
  method: 'km',
  methods: ['km', 'massues'],

  mutations: {
    'kms_total' (value) {
      this.kmsTotal = value
      this.render()
    },
    drops_total: updateCounter,
    // kms: updateCounter,
  },

  render () {
    let value = this.kmsTotal
    if (this.method === 'massues') {
      value = Math.round(value * 1000 / 0.52)
    }
    document.querySelector('[data-u="unit"').textContent = this.method
    updateCounter(value, 'kms_total')
  },

  onCreated () {
    setInterval(() => {
      this.method = this.method === 'km' ? 'massues' : 'km'
      this.render()
    }, this.interval)
  },
}
