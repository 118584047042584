import * as components from './components'

class Store {
  constructor () {
    this._subscribers = new Map()
    this.state = new Proxy({},
      {
        set: (state, prop, value) => {
          if (prop in state && state[prop] === value) return true

          state[prop] = value
          if (this._subscribers.has(prop) && value !== null) {
            this.mutateComponents(prop, value)
          } else {
            // console.warn('There is no components listening to ' + prop)
          }
          return true
        },
      },
    )
  }

  register (componentName, props) {
    for (const prop of props) {
      if (!this._subscribers.has(prop)) {
        this._subscribers.set(prop, new Set())
      }
      this._subscribers.get(prop).add(componentName)
    }
  }

  setProp (prop, value) {
    this.state[prop] = value
  }

  setData (data) {
    for (const [prop, value] of Object.entries(data)) {
      this.state[prop] = value
    }
  }

  mutateComponents (prop, value) {
    this._subscribers.get(prop).forEach(componentName => {
      const component = components[componentName]
      if ('mutations' in component) {
        component.mutations[prop].bind(component)(value, prop)
      } else {
        component.update(value, prop)
      }
    })
  }
}

export default new Store()
